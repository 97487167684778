.password {
  position: relative;

  .icon {
    position: absolute;
    top: 1rem;
    right: 1rem;
    cursor: pointer;
  }
}
