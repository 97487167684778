section.main {
  background: #eee;
}
.toggle-btn {
  width: 45px;
  height: 23px;
  background-color: #ccc;
  display: flex;
  border-radius: 50px;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  position: relative;
  transform: scale(1.5);
  cursor: pointer;
}

.toggled {
  background-color: rgba(95, 201, 52, 1);
}

.ball {
  width: 20px;
  height: 20px;
  background-color: white;
  position: absolute;
  top: 2px;

  left: 2px;
  border-radius: 50%;
  transition: transform 0.2s linear;
}

.move {
  transform: translateX(22px);
}
.sub-plans {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-direction: row;
}

.subs {
  width: 100;
  display: flex;
  justify-content: center;
}

.priceButtons {
  /* margin: 0 auto; */
  margin-top: 2rem;
}

@media screen and (max-width: 800px) {
  .sub-plans {
    flex-direction: column;
  }
  .priceButtons {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin: auto;
    margin-top: 2rem;
    text-align: center;
  }
}
