.price-box {
  width: 30rem;
  text-align: center;
  background-color: var(--color-white);
  padding-bottom: 3rem;
  margin: 2rem 1.5rem;
}
.price-box .plan {
  color: #fff;
  font-size: 2rem;
  font-weight: bold;
}

.price-box h4 span {
  font-size: 1.6rem;
  font-weight: normal;
  vertical-align: middle;
}

.price-box h4 span:nth-child(2) {
  font-size: 3rem;
  margin-bottom: 3rem;
}

.price-box ul {
  list-style: none;
  margin-bottom: 2rem;
}

.price-box ul li {
  font-size: 1.4rem;
  font-weight: 400;
  text-transform: capitalize;
  padding: 1.2rem 0;
  border-top: 1px solid rgb(227, 217, 217);
  color: #333;
}

.price-box ul li:last-child {
  border-bottom: 1px solid rgb(227, 217, 217);
}

.price-box button {
  font-size: 1.4rem;
  text-decoration: none;
  /* background-color: var(--color-success); */
  padding: 1.2rem 3rem;
  cursor: pointer;

  border: 1px solid transparent;
  border-radius: 5px;
  margin-bottom: 3rem;
  color: #fff;
  transition: all 0.3s;
}

.price-box button:hover {
  font-size: 1.5rem;
}

.theme0 {
  background: linear-gradient(
    to right bottom,
    rgb(50, 146, 11),
    rgba(95, 201, 52, 1)
  );
}
.theme1 {
  background: linear-gradient(
    to right bottom,
    rgb(34, 134, 255),
    rgb(11, 135, 193)
  );
}

.theme2 {
  background: linear-gradient(
    to right bottom,
    rgba(238, 142, 33, 1),
    rgba(104, 50, 5, 1)
  );
}
.theme3 {
  background: linear-gradient(
    to right bottom,
    rgba(234, 67, 187, 1),
    rgba(80, 3, 71, 1)
  );
}

@media screen and (max-width: 800px) {
  .price-box {
    width: 35rem;
    margin: 2rem auto;
  }
}
