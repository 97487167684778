.user-summary {
  width: 100%;
  .info-summary {
    display: flex;
    flex-wrap: wrap;
  }
}

.ccard {
  border: 1px solid #ccc;
  // border-bottom: 3px solid var(--light-blue);
  padding: 5px;
  background-color: #f5f6fa;
}

.ccard1 {
  background-color: #b624ff;
}
.ccard2 {
  background-color: #32963d;
}
.ccard3 {
  background-color: #03a5fc;
}
.ccard4 {
  background-color: #c41849;
}
