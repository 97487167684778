.create-ad {
  .flex-between {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  .card-adForm {
    width: 100%;
    min-width: 300px;
    max-width: 300px;
    padding: 1rem;
    border: 1px solid #333;
    margin-right: 1rem;
    margin-bottom: 1rem;
  }
  .profile-photo {
    width: 100%;
    // border: 1px solid red;
    background-color: var(--light-blue);
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;

    & img {
      width: 200px;
      height: 200px;
      border: 1px solid #030b6b;
      border-radius: 50%;
    }

    & h3 {
      color: #fff;
      font-size: 2rem;
      text-align: center;
    }
  }
  form {
    label {
      display: block;
      font-size: 1.4rem;
      font-weight: 500;
    }
    input[type="text"],
    input[type="number"],
    input[type="file"],
    input[type="email"],
    select,
    textarea,
    input[type="password"] {
      display: block;
      font-size: 1.6rem;
      font-weight: 300;
      padding: 1rem;
      margin: 1rem auto;
      width: 100%;
      border: 1px solid #777;
      border-radius: 3px;
      outline: none;

      &.url-prefix {
        width: 20%;
        margin-right: 5px;
        font-size: 1.7rem;
      }
    }
  }

  .image-preview {
    width: 100%;
    height: 100%;
    max-height: 280px;
    background: var(--dark-blue);
    padding: 1rem;
    border-radius: 3px;
    overflow: hidden;
    // display: none;
    transition: all 0.3s;
  }

  .image-preview img {
    width: 100%;
  }

  .image-show {
    display: block;
  }
}

.ad-details {
  h3 {
    text-align: left;
  }

  .price {
    position: relative;
    font-size: 2.5rem;
    font-weight: bold;
    color: white;
    margin-right: 1rem;
  }
  .price::after {
    position: absolute;
    content: "";
    left: -3px;
    top: 50%;
    width: 110%;
    height: 2px;
    background-color: red;
  }
  .salePrice {
    // position: relative;
    font-size: 2.5rem;
    font-weight: bold;
    color: white;
    margin-right: 1rem;
    background-color: var(--color-success);
    padding: 0 10px;
    border: 1px solid transparent;
    border-radius: 3px;
  }

  .desc {
    font-size: 1.4rem;
    margin-bottom: 5px;
  }

  .coupon {
    font-size: 2.1rem;
    font-weight: bold;
    color: white;
  }
}
