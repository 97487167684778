.view-domains {
  color: #333;
  .table {
    padding: 5px;
    width: 100%;
    overflow-x: auto;

    .search {
      width: 100%;
      max-width: 300px;
    }

    table {
      border-collapse: collapse;
      width: 100%;
      font-size: 1.3rem;

      thead {
        border-top: 2px solid var(--light-blue);
        border-bottom: 2px solid var(--light-blue);
      }

      th {
        border: 1px solid #eee;
      }

      th,
      td {
        vertical-align: top;
        text-align: left;
        padding: 8px;

        &.icons {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          > * {
            margin-right: 7px;
            cursor: pointer;
            vertical-align: middle;
            align-self: center;
          }
        }
      }

      tr {
        border-bottom: 1px solid #ccc;
      }

      tr:nth-child(even) {
        background-color: #fff;
      }
      tbody {
        tr:hover {
          // cursor: pointer;
          background-color: rgba(121, 136, 149, 0.3);
        }
      }
    }
  }
}

.sort {
  label {
    font-size: 1.4rem;
    font-weight: 500;
    margin: 0 5px;
  }
  select {
    font-size: 1.6rem;
    font-weight: 300;
    padding: 4px 8px;
    margin: 0 5px 0 0;
    border: none;
    border-bottom: 2px solid #777;
    outline: none;
  }
}

.copy-link {
  // position: relative;
  display: flex;
}

.copy-icon {
  // position: absolute;
  // top: 7px;
  cursor: pointer;
  color: var(--color-purple);
}

.tooltip {
  background-color: var(--light-blue);
  color: #fff;
}

.default-ad {
  position: relative;
  border-left: 5px solid green;
  border-right: 5px solid green;
  // background-color: lightgreen;
}
.default-ad::after {
  position: absolute;
  content: "Default";
  left: 5px;
  bottom: 1px;
  background-color: orangered;
  color: white;
  padding: 1px 2px;
  border-radius: 3px;
}

.verified {
  background-color: rgb(47, 196, 39);
  // padding: 3px;
  border-radius: 3px;
  color: #fff;
}
.unverified {
  background-color: rgb(245, 10, 10);
  // padding: 3px;
  border-radius: 3px;
  color: #fff;
}
