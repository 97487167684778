.create-page {
  .flex-between {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  .card-adForm {
    width: 100%;
    min-width: 300px;
    max-width: 400px;
    padding: 1rem;
    border: 1px solid #333;
    margin-right: 1rem;
    margin-bottom: 1rem;
  }

  form {
    label {
      display: block;
      font-size: 1.4rem;
      font-weight: 500;
    }
    input[type="text"],
    input[type="number"],
    input[type="file"],
    input[type="email"],
    select,
    textarea,
    input[type="password"] {
      display: block;
      font-size: 1.6rem;
      font-weight: 300;
      padding: 1rem;
      margin: 1rem auto;
      width: 100%;
      border: 1px solid #777;
      border-radius: 3px;
      outline: none;

      &.url-prefix {
        width: 35%;
        margin-right: 5px;
        font-size: 1.7rem;
      }
    }
  }
}

.addLink {
  color: var(--dark-blue);
  font-size: 1.3rem;
  font-weight: bold;

  border: 2px solid var(--color-success);
  border-radius: 3px;
  padding: 2px 4px;
  cursor: pointer;
}

.ad-preview {
  width: 100%;
  min-width: 60rem;
}

.ad-head {
  background-color: var(--dark-blue);
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-text {
  font-size: 3rem;
  font-weight: 300;
  color: #fff;
}

.logo-text span {
  font-weight: 600;
  color: orangered;
}

.ad-btn {
  margin: 2rem 0;
}

.ad-details {
  width: 100%;
  max-width: 300px;
  padding: 1rem;
  border: 1px solid #030b6b;
}

.image-preview {
  width: 100%;
  height: 100%;
  max-height: 280px;
  background: var(--dark-blue);
  padding: 1rem;
  border-radius: 3px;
  overflow: hidden;
  // display: none;
  transition: all 0.3s;
}

.image-preview img {
  width: 100%;
}

.image-show {
  display: block;
}

.ad-details {
  h3 {
    text-align: left;
    font-size: 2rem;
  }
  p {
    font-size: 1.2rem;
  }
  .price {
    position: relative;
    // font-size: 2.5rem;
    font-size: 2rem;
    font-weight: bold;
    color: white;
    margin-right: 1rem;
  }
  .price::after {
    position: absolute;
    content: "";
    left: -3px;
    top: 50%;
    width: 110%;
    height: 2px;
    background-color: red;
  }
  .salePrice {
    // position: relative;
    // font-size: 2.5rem;
    font-size: 2rem;
    font-weight: bold;
    color: white;
    margin-right: 1rem;
    background-color: var(--color-success);
    padding: 0 10px;
    border: 1px solid transparent;
    border-radius: 3px;
  }

  .desc {
    font-size: 1.4rem;
    margin-bottom: 5px;
  }

  .coupon {
    font-size: 1.5rem;
    font-weight: bold;
    color: white;
  }
}
