/* Loading */
.wrapper {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9;
}

.loader {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
}

$colors: #0982e4, #0ca1e7, #00bcd4, #ff7a28, #ff7119, #ff32cf, #f131de, #f404e0;

.spinner {
  // position: absolute;
  display: block;
  // top: 50%;
  // left: 50%;
  z-index: 9999;
  height: 40px;
  width: 40px;
  text-align: center;
  border: 1px solid var(--light-blue);
  border-radius: 50%;
  // transform: translate(-50%, -50%);
}

[class^="ball-"] {
  position: absolute;
  display: block;
  // left: 30px;
  margin-left: 18px;
  width: 8px;
  height: 8px;
  border-radius: 6px;
  transition: all 0.5s;
  animation: circleRotate 4s both infinite;
  transform-origin: 0 250% 0;
}

@keyframes circleRotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(1440deg);
  }
}

@for $i from 1 through 8 {
  .ball-#{$i} {
    z-index: -#{$i};
    background-color: nth($colors, $i);
    animation-timing-function: cubic-bezier(0.5, 0.3 * $i, 0.9, 0.9);
  }
}
