.dcard {
  width: 100%;
  min-width: 400px;
  padding: 1rem;
  border: 1px solid #333;
  margin-bottom: 1rem;
}

// .dcard1 {
//   border: 1px solid purple;
// }
.dcard-purple {
  border: 1px solid purple;
  // max-width: 400px;
  // display: inline-block;
}

.dcard > * {
  margin-bottom: 5px;
}

.group {
  padding: 1rem;
  border: 1px solid #333;
}

.sort {
  select {
    font-size: 1.6rem;
    font-weight: 300;
    padding: 5px;

    width: 100%;
    border: 1px solid #777;
    border-radius: 3px;
    outline: none;
  }
}

.info-text-dark {
  color: #fff;
}

.dcard1 {
  border: 2px solid #b624ff;
}
.dcard2 {
  border: 2px solid #32963d;
}
.dcard3 {
  border: 2px solid #03a5fc;
}
.dcard4 {
  border: 2px solid #c41849;
}

.link-border {
  border: solid 1px #ccc;
  padding: 2px 4px;
  margin-right: 5px;
}
